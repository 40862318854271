import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

const Logo = ({ fill = "white" }) => {
  return (
    <LogoContainer>
      <svg
        width="358"
        height="87"
        viewBox="0 0 358 87"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Desktop"
      >
        <text className="logo_text" transform="matrix(1 0 0 1 11 37.9287)">
          <tspan x="0" y="0">
            GISELA{" "}
          </tspan>
          <tspan x="0" y="24">
            MIRANDA DIFINI
          </tspan>
        </text>
      </svg>
      <svg
        width="44"
        height="40"
        viewBox="0 0 44 40"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile"
      >
        <path d="M34,37.5H10c-4.42,0-8-3.58-8-8V10.59c0-4.42,3.58-8,8-8h24c4.42,0,8,3.58,8,8V29.5C42,33.92,38.42,37.5,34,37.5z" />
        <text transform="matrix(1 0 0 1 7 29.7871)">
          <tspan
            x="0"
            y="0"
            className="mobile"
            fill={fill === "#fff" ? "#000" : "#fff"}
          >
            G
          </tspan>
          <tspan
            x="20"
            y="0"
            className="mobile"
            fill={fill === "#fff" ? "#000" : "#fff"}
          >
            I
          </tspan>
        </text>
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }
  .logo_text {
    font-size: 22px;
    font-weight: 700;
  }
  .mobile {
    font-weight: 700;
    font-size: 26px;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
